import React from 'react'
import './InsightsContent.scss'
import AllFeed from '../AllFeed/AllFeed'
import qs from 'query-string'

export class InsightsContent extends React.Component {
  render() {
    const { location } = this.props
    let preFilter
    if (location.search) {
      let params = qs.parse(location.search)
      if (params.filter) {
        preFilter = params.filter
      }
    }
    return (
      <div className="insights-content">
        <AllFeed
          showFilters
          preFilter={preFilter}
          title={false}
          posts={this.props.posts}
        />
      </div>
    )
  }
}

export default InsightsContent
