import React from 'react'
import './InsightsHero.scss'

export class InsightsHero extends React.Component {
  render() {
    return (
      <div className="insights-hero">
        <div className="text">
          <h1>Cloud Native Insights from our Team</h1>
        </div>
      </div>
    )
  }
}

export default InsightsHero
