import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import '../templates/base.scss'
import InsightsContent from '../components/InsightsContent/InsightsContent'
import SEO from '../components/SEO'
import { Location } from '@reach/router'
import { InsightsHero } from '../components/InsightsHero/InsightsHero'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'

const Insights = ({ data, pageContext }) => {
  const posts = data ? data.allContentfulPost.edges : []
  const postNode = {
    title: `Insights - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="Insights" customTitle />
      <Helmet>
        <title>{`Insights - ${config.siteTitle}`}</title>
      </Helmet>
      <InsightsHero />
      <Container color={'light-grey'}>
        <Location>
          {({ location }) => {
            return <InsightsContent location={location} posts={posts} />
          }}
        </Location>
      </Container>
      <CallToActionBanner type={'signup'} />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPost(limit: 100) {
      edges {
        node {
          title
          id
          type
          slug
          shortDescription
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Insights
